<template>
  <form @submit.prevent="saveTradePrice" class="-my-3">
    <div v-if="mode == 'create'" class="my-3">
      <label class="uppercase font-semibold text-sm">Price Band</label>
      <div class="relative w-full">
        <select v-model="tradePrice.band_code" required class="bge-input bge-select rounded">
          <template v-for="option in bandCodes">
            <option
              :key="option.band_code"
              :value="option.band_code"
            >{{ option.band_code + ': ' + option.description }}</option>
          </template>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
    <div v-if="mode == 'edit'" class="my-3">
      <h4 class="font-bold text-lg">{{ tradePrice.band_code + ': ' + tradePrice.description }}</h4>
    </div>
    <div class="flex flex-col w-full my-3">
      <label class="uppercase font-semibold text-sm">
        Price
        <span class="text-red-400">*</span>
      </label>
      <div
        class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
      >
        <div class="flex justify-center items-center p-2">
          <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
            <path
              d="M6,21V19C10,17 9.5,13 9.5,13H7V11H9.5C8.5,6.5 10,3 14,3C16,3 17,3.5 17,3.5V5.5C11,3.5 11,8 11.5,11H16V13H11.5C11.5,13 12,17 9.5,19H18V21H6Z"
            />
          </svg>
        </div>
        <input
          v-model="tradePrice.price"
          required
          class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
        />
      </div>
    </div>
    <div class="flex flex-col w-full my-3">
      <label class="uppercase font-semibold text-sm">Delivery Price</label>
      <div
        class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
      >
        <div class="flex justify-center items-center p-2">
          <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
            <path
              d="M6,21V19C10,17 9.5,13 9.5,13H7V11H9.5C8.5,6.5 10,3 14,3C16,3 17,3.5 17,3.5V5.5C11,3.5 11,8 11.5,11H16V13H11.5C11.5,13 12,17 9.5,19H18V21H6Z"
            />
          </svg>
        </div>
        <input
          v-model="tradePrice.delivery_out"
          class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
        />
      </div>
    </div>
    <button
      type="submit"
      class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 text-secondary py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
    >
      <svg
        class="stroke-current h-5 w-5 mr-2"
        fill="none"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        viewBox="0 0 24 24"
      >
        <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
        <path d="M17 21v-8H7v8M7 3v5h8" />
      </svg>
      <span v-if="mode == 'create'">Add Price Band</span>
      <span v-if="mode == 'edit'">Update Price Band</span>
    </button>
  </form>
</template>

<script>
export default {
  name: "AddEditTradePrice",
  props: {
    tradePrice: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mode: "",
      bandCodes: [
        { band_code: "1", description: "List Price" },
        { band_code: "2", description: "Agent Grade A" },
        { band_code: "3", description: "Agent Grade B" },
        { band_code: "4", description: "Agent Grade C" },
        { band_code: "7", description: "CASTROL Grade D" },
        { band_code: "8", description: "Web Price" },
        { band_code: "9", description: "SRP" }
      ]
    };
  },
  mounted() {
    if (this.tradePrice.band_code) {
      this.mode = "edit";
    } else {
      this.mode = "create";
    }
  },
  watch: {
    "tradePrice.band_code": function() {
      let band = this.$_.find(
        this.bandCodes,
        b => b.band_code == this.tradePrice.band_code
      );
      this.tradePrice.band_code = band.band_code;
      this.tradePrice.description = band.description;
    }
  },
  methods: {
    saveTradePrice: function() {
      this.tradePrice.price = parseFloat(this.tradePrice.price);
      this.tradePrice.delivery_out = this.tradePrice.delivery_out
        ? parseFloat(this.tradePrice.delivery_out)
        : 0;

      this.$emit("complete", this.tradePrice);
    }
  }
};
</script>