<template>
  <div>
    <form @submit.prevent="updateLineItem" class="flex flex-wrap py-4">
      <div class="w-full py-2">
        <label class="uppercase font-semibold text-sm">Code</label>
        <input
          v-model="lineItem.product_code"
          class="uppercase w-full border rounded border-gray-300 bg-gray-300 py-2 px-4"
          type="text"
          disabled
        />
      </div>
      <div class="w-full py-2">
        <label class="uppercase font-semibold text-sm">Name</label>
        <input
          v-model="lineItem.name"
          class="w-full border rounded border-gray-300 bg-gray-300 py-2 px-4"
          type="text"
          disabled
        />
      </div>
      <div class="w-full py-2">
        <label class="uppercase font-semibold text-sm">Quantity</label>
        <input
          v-model="lineItem.qty"
          class="bge-input bge-input-spacing rounded w-full"
          type="number"
          required
          placeholder="Quantity..."
        />
      </div>
      <div class="w-full">
        <button
          type="button"
          @click="updateLineItem"
          class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 text-secondary py-1 px-3 rounded border border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-100"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Update Line Item</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    lineItem: {
      required: true,
      type: Object
    }
  },
  methods: {
    updateLineItem: function() {
      if (this.lineItem.qty != null) {
        this.lineItem.qty = parseInt(this.lineItem.qty);
        this.$emit("complete");
      }
    }
  }
};
</script>