<template>
  <div>
    <h4 class="text-lg font-semibold">Search for Product:</h4>
    <input
      type="text"
      class="w-full border rounded-l border-gray-400 bg-gray-100 hover:border-primary focus:border-primary py-2 px-4 focus:shadow mt-4"
      placeholder="Product name..."
      @click="showingSearchResults = true"
      v-model="searchTerm"
      @keydown="searchProducts"
    />
    <div
      v-if="showingSearchResults"
      class="border border-gray-300 bg-white rounded mt-1 flex flex-col justify-center max-h-50vh"
    >
      <div class="flex flex-col overflow-y-scroll">
        <template v-for="result in searchResults">
          <button
            type="button"
            :key="result.product_code"
            @click="selectProduct(result)"
            class="hover:bg-orange-200 flex flex-col py-3 transition duration-100 focus:bg-orange-200 text-left px-4"
          >
            <span class="font-bold text-lg">{{ result.product_code }}:</span>
            <span>{{ result.name }}</span>
          </button>
        </template>
        <p class="mx-auto py-4" v-if="searchResults == ''">No products match your search</p>
      </div>
    </div>
    <form @submit.prevent="addLineItem" v-show="selectedLineItem.product_code" class="mt-1">
      <div class="border border-gray-300 p-4 rounded">
        <h4 class="text-lg font-semibold">Selected Product:</h4>
        <div class="flex items-center">
          <span class="font-bold">{{ selectedLineItem.product_code }}:</span>
          <span class="ml-1">{{ selectedLineItem.name }}</span>
        </div>
        <input
          @click="showingSearchResults = false"
          required
          v-model="selectedLineItem.qty"
          class="bge-input bge-input-spacing w-full rounded mt-2"
          type="number"
          placeholder="Quantity..."
        />
      </div>
      <button
        type="submit"
        @click="addLineItem"
        class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 text-secondary py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Add Line Item</span>
      </button>
    </form>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      searchTerm: "",
      searchResults: [],
      selectedLineItem: {},
      showingSearchResults: true
    };
  },
  methods: {
    searchProducts: _.debounce(async function() {
      this.searchResults = "";
      if (this.searchTerm != "" && this.searchTerm.length >= 3) {
        try {
          this.searchResults = await this.ProductService.getProducts(
            this.searchTerm
          );
        } catch (error) {
          console.error(error);
        }
      }
    }, 500),
    selectProduct: function(product) {
      this.selectedLineItem.name = product.name;
      this.selectedLineItem.product_code = product.product_code;
      this.selectedLineItem.qty = null;
      this.$forceUpdate();
      this.showingSearchResults = false;
    },
    addLineItem: function() {
      if (this.selectedLineItem.qty != null) {
        this.selectedLineItem.qty = parseInt(this.selectedLineItem.qty);
        this.$emit("complete", this.selectedLineItem);
      }
    }
  }
};
</script>